import { RestrictionType } from "@iabtechlabtcf/core";
function isPurposeEnabledForVendor(model, id, isLegInt, _ref) {
  var _publisherRestriction;
  let {
    id: vendorId,
    legIntPurposes
  } = _ref;
  const publisherRestrictions = model.publisherRestrictions.getRestrictions(vendorId);
  const disabledPurposes = publisherRestrictions.map(_ref2 => {
    let {
      purposeId,
      restrictionType
    } = _ref2;
    return purposeId === id && restrictionType === RestrictionType.NOT_ALLOWED && purposeId;
  }).filter(Boolean);
  if (disabledPurposes.indexOf(id) > -1) {
    return false;
  }
  let legIntRestriction = (_publisherRestriction = publisherRestrictions.filter(_ref3 => {
    let {
      purposeId,
      restrictionType
    } = _ref3;
    return purposeId === id && restrictionType !== RestrictionType.NOT_ALLOWED;
  })[0]) === null || _publisherRestriction === void 0 ? void 0 : _publisherRestriction.restrictionType;

  // Fallback to default setting
  if (!legIntRestriction) {
    legIntRestriction = legIntPurposes.indexOf(id) > -1 ? RestrictionType.REQUIRE_LI : RestrictionType.REQUIRE_CONSENT;
  }
  if (isLegInt && legIntRestriction === RestrictionType.REQUIRE_CONSENT) {
    return false;
  } else if (!isLegInt && legIntRestriction === RestrictionType.REQUIRE_LI) {
    return false;
  }
  return true;
}
export { isPurposeEnabledForVendor };